import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
    AlertColor,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    AdCreativeSource,
    AdSize,
    AdTagImportSource,
    AdTagPlacement,
    AdTagPlacementData,
    AdTagPlacementStatus,
    AdTagTemplate,
    AdTagType,
    DspPlatform,
} from '../../types/AdTag';
import { FileInfo } from '../../types/S3';
import ApiService from '../../ApiService';
import CDButton from '../../components/CDButton';
import Row from '../../components/Row';
import { CDTextField } from '../../components/CDTextField';
import AppLoader from '../../components/AppLoader';
import CDAutocompleteEndless from '../../components/CDAutocompleteEndless';
import { Dealer } from '../../types/Dealer';
import { Campaign, CampaignAdGroup } from '../../types/Campaign';

const initialValues = {
    dealer: null as Dealer | null,
    campaign: null as Campaign | null,
    campaignAdGroup: null as CampaignAdGroup | null,
    tagType: 'VAST_VIDEO' as keyof typeof AdTagType,
    adTagTemplateId: 1, // "Static Image HTML" as default
    adSize: '160x600' as keyof typeof AdSize,
    dspPlatform: 'AMAZON' as keyof typeof DspPlatform,
    adCreativeSource: 'FTP' as keyof typeof AdCreativeSource,
    adTagImportSourceId: null,
    filenameKey: null,
    filename: null,
    adName: null as string | null,
};

export type EditAdTagPlacementRef = {
    edit: (adTagPlacement?: AdTagPlacement) => void;
};

type Props = {
    reloadGrid(): void;
    showInfoMessage: (alertColor: AlertColor, message: string, onClose?: () => void) => void;
};

const EditAdTagPlacement = forwardRef<EditAdTagPlacementRef, Props>(({ reloadGrid, showInfoMessage }, ref) => {
    const [sources, setSources] = useState<AdTagImportSource[]>([]);
    const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);
    const [adTagTemplates, setAdTagTemplates] = useState<AdTagTemplate[] | null>(null);
    const [mediaFiles, setMediaFiles] = useState<FileInfo[] | null>(null);
    // const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
    //     message: null,
    // });

    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState(initialValues);

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        edit(atp?: AdTagPlacement) {
            if (atp) {
                setFormData((prev) => ({
                    ...prev,
                    dealer: atp.dealer,
                    campaignAdGroup: atp.campaignAdGroup,
                    tagType: atp.tagType,
                    adTagTemplateId: atp.adTagTemplateId || 1,
                    adSize: atp.adSize || '160x600',
                    dspPlatform: atp.dspPlatform,
                    adCreativeSource: atp.adCreativeSource,
                    adName: atp.adName,
                }));
            }
            handleDialogToggle();
        },
    }));

    useEffect(() => {
        if (!isOpen) return;
        if (sources.length) return;
        ApiService.getAdTagImportSources()
            .then((response) => setSources(response.data))
            .catch(() => {});
    }, [isOpen]);

    useEffect(() => {
        setAdNameToFormData();
    }, [formData.dealer, formData.dspPlatform, formData.filenameKey]);

    const setAdNameToFormData = () => {
        const { dealer, dspPlatform, filenameKey } = formData;
        setFormData((prev) => ({
            ...prev,
            adName: [dealer?.dealerName, dspPlatform, filenameKey].filter(Boolean).join(' - '),
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {
            dealer,
            campaign,
            campaignAdGroup,
            tagType,
            adTagTemplateId,
            adSize,
            dspPlatform,
            adName,
            adCreativeSource,
            adTagImportSourceId,
            filenameKey,
            filename,
        } = formData;

        const preparedData: AdTagPlacementData = {
            dealerId: dealer?.id || 0,
            campaignId: campaign?.id,
            campaignAdGroupId: campaignAdGroup?.id,
            dspPlatform,
            adName,
            tagType,
            adTagTemplateId,
            adSize,
            adCreativeSource,
            adTagImportSourceId,
            filenameKey,
            filename,
        };

        let p = await ApiService.createAdTagPlacement(preparedData);
        handleDialogClose();
        p = await ApiService.publishAdTagPlacement(p.data.id);
        reloadGrid();
        if (p.data.status === AdTagPlacementStatus.ERROR) {
            showInfoMessage('error', `Error processing ${p.data.adName}: ${p.data.processMessage}`);
        }
    };

    const handleDealerChange = (dealer: Dealer | null) => {
        setCampaigns(null);
        setFormData((prev) => ({ ...prev, dealer, campaign: null, campaignAdGroup: null }));
        if (dealer === null) return;
        setIsLoading(true);
        ApiService.getCampaignsForDealer(dealer.id || 0)
            .then((response) => setCampaigns(response.data))
            .finally(() => setIsLoading(false));
    };

    const handleCampaignChange = (event: React.ChangeEvent<{}>, campaign: Campaign | null) => {
        setFormData((prev) => ({ ...prev, campaign, campaignAdGroup: null }));
    };

    const handleAdGroupChange = (event: React.ChangeEvent<{}>, value: any) => {
        setFormData((prev) => ({ ...prev, campaignAdGroup: value }));
    };

    const handleAdTagTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMediaFiles(null);
        setFormData((prev) => ({
            ...prev,
            tagType: event.target.value as keyof typeof AdTagType,
            adTagImportSourceId: null,
        }));
        if (!adTagTemplates) ApiService.getTemplates().then((response) => setAdTagTemplates(response.data));
    };

    const handleAdTagTemplateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const templateId: any = +event.target.value;
        setFormData((prev) => ({ ...prev, adTagTemplateId: templateId }));
    };

    const handleAdSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, adSize: event.target.value as keyof typeof AdSize }));
    };

    const handleDspPlatformChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, dspPlatform: event.target.value as keyof typeof DspPlatform }));
    };

    const handleAdCreativeSourceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, adCreativeSource: event.target.value as keyof typeof AdCreativeSource }));
    };

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const adTagImportSourceId: any = +event.target.value;
        setMediaFiles(null);
        setFormData((prev) => ({ ...prev, adTagImportSourceId, filenameKey: null, filename: null }));
        ApiService.getAdTagIncomingS3Files(
            adTagImportSourceId,
            formData.tagType === 'VAST_VIDEO' ? '.mp4' : '.jpg,.jpeg,.png'
        ).then((response) => setMediaFiles(response.data));
    };

    const handleVideoFileChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        const filename: any = value || '';
        const filenameKey: any = filename.split('.')[0] || '';
        setFormData((prev) => ({ ...prev, filenameKey, filename }));
    };

    const clearFormData = () => {
        setFormData(initialValues);
        setCampaigns(null);
    };

    const handleDialogToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleDialogClose = () => {
        handleDialogToggle();
        clearFormData();
    };

    return (
        <>
            <Dialog open={isOpen} maxWidth="md" fullWidth>
                <AppLoader loading={isLoading} />

                <DialogTitle>Setup Ad Tag</DialogTitle>

                {isOpen && (
                    <form onSubmit={handleSubmit}>
                        <DialogContent sx={{ padding: '10px' }}>
                            <CDAutocompleteEndless
                                sx={{ padding: '10px' }}
                                required
                                label="Select Advertiser"
                                // value={formData.dealer}
                                onChange={handleDealerChange}
                                handleFetch={async (page, name) => {
                                    return ApiService.getAllDealers(page, name);
                                }}
                                getOptionLabel={(option: Dealer) => option.dealerName}
                            />

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select Creative Source"
                                    select
                                    value={formData.adCreativeSource}
                                    onChange={handleAdCreativeSourceChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(AdCreativeSource).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {AdCreativeSource[key as keyof typeof AdCreativeSource]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select Tag Type"
                                    select
                                    value={formData.tagType}
                                    onChange={handleAdTagTypeChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(AdTagType).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {AdTagType[key as keyof typeof AdTagType]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            {adTagTemplates &&
                                formData.tagType === 'HTML_IMAGE' &&
                                formData.adCreativeSource === 'FTP' && (
                                    <>
                                        <Row sx={{ padding: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Select Tag Template"
                                                select
                                                value={formData.adTagTemplateId}
                                                onChange={handleAdTagTemplateChange}
                                                SelectProps={{ native: false }}
                                            >
                                                {adTagTemplates.map((t) => (
                                                    <MenuItem key={t.id} value={t.id}>
                                                        {t.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Row>
                                        <Row sx={{ padding: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Select Ad Size"
                                                select
                                                value={formData.adSize}
                                                onChange={handleAdSizeChange}
                                                SelectProps={{ native: false }}
                                            >
                                                {Object.keys(AdSize).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {AdSize[key as keyof typeof AdSize]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Row>
                                    </>
                                )}

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select DSP Platform"
                                    select
                                    value={formData.dspPlatform}
                                    onChange={handleDspPlatformChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(DspPlatform).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {DspPlatform[key as keyof typeof DspPlatform]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            {formData.adCreativeSource === 'FTP' && (
                                <>
                                    <Row sx={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            required
                                            size="small"
                                            label="Select Source Folder"
                                            select
                                            onChange={handleSourceChange}
                                            value={formData.adTagImportSourceId || ''}
                                            SelectProps={{ native: false }}
                                        >
                                            {sources.map((source) => (
                                                <MenuItem key={source.id} value={source.id}>
                                                    {source.s3Bucket}/{source.s3IncomingPath}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Row>

                                    {mediaFiles && (
                                        <Autocomplete
                                            sx={{ padding: '10px' }}
                                            options={mediaFiles.map((file) => file.name)}
                                            onChange={handleVideoFileChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    required
                                                    size="small"
                                                    label="Select File"
                                                    name="filename"
                                                />
                                            )}
                                        />
                                    )}

                                    <Row sx={{ padding: '10px' }}>
                                        <CDTextField
                                            fullWidth
                                            required
                                            name="filenameKey"
                                            label="Filename Key"
                                            variant="outlined"
                                            value={formData.filenameKey}
                                            onChange={handleChange}
                                        />
                                    </Row>

                                    <Row sx={{ padding: '10px' }}>
                                        <CDTextField
                                            fullWidth
                                            required
                                            name="adName"
                                            label="Ad Name"
                                            variant="outlined"
                                            value={formData.adName}
                                            onChange={handleChange}
                                        />
                                    </Row>
                                </>
                            )}
                            {formData.adCreativeSource === 'CREATIVE_STUDIO' && (
                                <Tooltip
                                    title="Creative Studio Ads that were not generated with an HTML or VAST Tag will not appear in this list."
                                    placement="left"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'white',
                                                color: 'black',
                                                boxShadow: 1,
                                                fontSize: 14,
                                            },
                                        },
                                    }}
                                >
                                    <Autocomplete
                                        sx={{ padding: '10px' }}
                                        // options={mediaFiles.map((file) => file.name)}
                                        options={[]}
                                        onChange={handleVideoFileChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required
                                                size="small"
                                                label="Select Ad Name"
                                                name="adName"
                                            />
                                        )}
                                    />
                                </Tooltip>
                            )}
                            {campaigns && (
                                <Autocomplete
                                    sx={{ padding: '10px' }}
                                    options={campaigns}
                                    getOptionLabel={(campaign) => campaign.campaignName || ''}
                                    onChange={handleCampaignChange}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth size="small" label="Select Campaign" />
                                    )}
                                />
                            )}

                            {formData?.campaign?.adGroups && (
                                <Autocomplete
                                    sx={{ padding: '10px' }}
                                    options={formData?.campaign?.adGroups}
                                    getOptionLabel={(ag) => ag.name || ''}
                                    onChange={handleAdGroupChange}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth size="small" label="Select Ad Group" />
                                    )}
                                />
                            )}
                        </DialogContent>

                        <DialogActions sx={{ padding: '10px 20px' }}>
                            <CDButton color="error" onClick={handleDialogClose}>
                                Cancel
                            </CDButton>

                            <CDButton type="submit" disabled={isLoading}>
                                Generate Ad Tag
                            </CDButton>
                        </DialogActions>
                    </form>
                )}
            </Dialog>
        </>
    );
});

export default EditAdTagPlacement;
